<template>
  <div class="collapse-content">
    <b-button
      block
      v-b-toggle="id"
      class="btn-collapse h6 p-4 mb-0 text-left d-flex justify-content-between align-items-center rounded"
    >
      <h6 class="pr-2 pr-md-0 font-weight-bold text-uppercase title">{{ title }}</h6>
      <hs-icon class="icon text-dark" variant="light" icon="chevron-up" v-bind:class="{ collapsed: visible }" />
    </b-button>
    <b-collapse :id="id" v-model="visible">
      <div class="b-collapse position-relative px-4 pt-0 pb-4">
        <slot></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: this.collapsed,
    };
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return this._uid.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
$bg-color-default: #fdfdfd;

.title {
  line-height: 20px;
  letter-spacing: -0.233333px;
  color: #9b9b9b;
}

.icon {
  transition: 0.3s transform ease-in-out;
}

.collapsed .icon {
  transform: rotate(180deg);
}

.collapse-content {
  background-color: $bg-color-default;
  border: 3px solid $grey-10;
  border-top-width: 1.5px;
  border-bottom-width: 1.5px;
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.2);
}

.btn-collapse {
  color: $grey-30;
  background-color: $bg-color-default;
  box-shadow: unset;

  &:active {
    background-color: $bg-color-default !important;
  }
  &:active:focus {
    box-shadow: none !important;
  }
}
</style>
