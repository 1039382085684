<template>
  <div
    class="card bg-white shadow-sm rounded mt-3 mx-2 mb-0"
    :class="{ 'fix-svg': isWebinar, selected: isSelected }"
    :style="{ backgroundImage: `url(${bgImg})` }"
  >
    <div class="card-body px-4 py-2 my-1">
      <div class="d-flex flex-column">
        <div class="d-flex flex-column mb-3">
          <span class="text-truncate text-uppercase h6 mb-0 funnel-type lh-150">
            {{ funnel.template_name }}
          </span>
          <span class="text-truncate h5 pt-2 mb-0 font-weight-bold funnel-name lh-150">{{ funnel.name }}</span>
        </div>
        <div v-if="this.funnel.leads_count" class="d-flex flex-row justify-content-between align-items-center pt-3">
          <div class="leads-count">
            <hs-icon variant="light" icon="user" />
            <span class="pl-2">{{
              $tc('sparkfunnels.campaigns.create.step_funnel_choice.leads_count', this.funnel.leads_count)
            }}</span>
          </div>
          <hs-button variant="link" class="use-funnel p-0" @click="$emit('selected', funnel)">
            {{ $t('sparkfunnels.campaigns.create.step_funnel_choice.use_funnel') }}
          </hs-button>
        </div>
        <span v-else class="font-weight-bold pb-1 need-leads lh-150">{{
          $t('sparkfunnels.campaigns.create.step_funnel_choice.neeed_leads')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    funnel: {
      required: true,
      type: Object,
    },
    selectedFunnel: {
      type: Object,
    },
  },
  computed: {
    bgImg() {
      let value = 1;
      switch (this.funnel.template_type) {
        case 'Funnel::Template::Bait':
          value = 1;
          break;
        case 'Funnel::Template::LeadOffer':
          value = 2;
          break;
        case 'Funnel::Template::Squeeze':
          value = 3;
          break;
        case 'Funnel::Template::Webinar':
          value = 4;
          break;
      }
      return require(`@/assets/images/campaigns/funnel-card/Vector-${value}.svg`);
    },
    isSelected() {
      return this.selectedFunnel ? this.selectedFunnel.id === this.funnel.id : false;
    },
    isWebinar() {
      return this.funnel.template_type === 'Funnel::Template::Webinar';
    },
  },
};
</script>

<style lang="scss" scoped>
.lh-150 {
  line-height: 150%;
}
.funnel-type {
  color: $grey-30;
  font-size: 12px;
}
.need-leads {
  font-size: 12px;
  color: #00dfed;
}
.card {
  background-repeat: no-repeat;
  background-position: bottom right;
  min-width: calc(40%);
  max-width: calc(40%);
}
.fix-svg {
  background-repeat: repeat-x;
}
.use-funnel {
  color: #00dfed;
}
.leads-count {
  color: $grey-30;
}
.selected {
  border: 2px solid $cyan;
}
@media only screen and (max-width: $screen-bs-lg) {
  .card {
    height: 100%;
    min-width: 330px;
    max-width: 330px;
  }
}
</style>
