<template>
  <div class="container d-flex flex-column align-items-center py-5 px-3">
    <h3
      class="text-center text-dark pb-4 mb-3 font-weight-bold"
      v-html="$t('sparkfunnels.campaigns.create.step_fill_name.fill_name')"
    ></h3>
    <hs-group
      :label="$t('sparkfunnels.campaigns.create.step_fill_name.input_label')"
      label-for="campaign-name"
      class="input-name w-100 pt-4 mt-3 mb-0 mb-md-2 "
    >
      <hs-input
        id="campaign-name"
        @blur="$v.campaignName.$touch()"
        v-model="campaignName"
        class="d-flex flex-column"
        :placeholder="$t('sparkfunnels.campaigns.create.step_fill_name.input_placeholder')"
        :state="!$v.campaignName.$error ? null : false"
      >
        <template slot="feedback" v-if="$v.campaignName.$error">
          <hs-form-invalid-feedback :state="false">
            <span v-if="!$v.campaignName.required">{{
              $t('sparkfunnels.campaigns.create.step_fill_name.error_messages.required')
            }}</span>
            <span v-if="!$v.campaignName.maxLength">{{
              $t('sparkfunnels.campaigns.create.step_fill_name.error_messages.max_length')
            }}</span>
          </hs-form-invalid-feedback>
        </template>
        <span slot="feedback" v-if="!$v.campaignName.$error" class="max-characteres text-dark">
          {{ $t('sparkfunnels.campaigns.create.step_fill_name.max_characteres') }}
        </span>
      </hs-input>
    </hs-group>
    <div
      class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-5"
    >
      <hs-button
        class="mb-2 mb-md-0 custom-button"
        variant="primary"
        @click="$emit('proceed')"
        :disabled="$v.campaignName.$invalid"
      >
        {{ $t('sparkfunnels.campaigns.create.step_fill_name.confirm_name') }}
      </hs-button>
      <hs-button class="mr-md-2 custom-button" variant="link" @click="$emit('recede')">{{
        $t('sparkfunnels.campaigns.create.step_fill_name.back_step')
      }}</hs-button>
    </div>
  </div>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapMutations } from 'vuex';

export default {
  computed: {
    campaignName: {
      get() {
        return this.$store.state.campaign.name;
      },
      set(name) {
        this.setName(name);
      },
    },
  },
  validations: {
    campaignName: {
      maxLength: maxLength(80),
      required,
    },
  },
  methods: { ...mapMutations('campaign', ['setName']) },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.input-name {
  max-width: 544px;
  /deep/ label {
    margin-bottom: 0;
    font-weight: bold;
  }
  /deep/ div {
    display: flex;
    flex-direction: column;
  }
}
.max-characteres {
  font-size: 12px;
  line-height: 150%;
}
</style>
