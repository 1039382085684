<template>
  <hs-sidebar class="tlw-relative" :opened="sidebarOpened">
    <template v-slot:content>
      <NoData
        v-if="!isLoading && !campaignsCount"
        :image="emptyState.image"
        :title="emptyState.title"
        :description="emptyState.description"
        variant="dark"
      >
        <div class="div-buttons">
          <hs-button
            id="btn-empty-state"
            class="custom-button tlw-relative"
            :variant="emptyState.premium ? 'outline-primary' : 'primary'"
            @click="emptyState.method"
          >
            <hs-icon
              v-if="emptyState.premium"
              icon="star"
              size="16px"
              variant="solid"
              class="premium-badge tlw-ml-1 tlw-p-1 rounded-circle tlw-absolute"
            />
            {{ emptyState.buttonTitle }}
          </hs-button>
        </div>
      </NoData>
      <div class="main-content tlw-flex tlw-flex-col tlw-px-2 tlw-pt-12 md:tlw-p-4 lg:tlw-p-12 tlw-w-full">
        <div class="tlw-flex-1">
          <div class="tlw-flex tlw-flex-col sm:tlw-flex-row tlw-items-center tlw-justify-between tlw-mb-12">
            <div>
              <h3 class="tlw-font-bold tlw-mb-2 tlw-md tlw-text-center md:tlw--left-0">
                {{ $t('sparkfunnels.campaigns.list.title') }}
              </h3>
              <p
                class="tlw-mt-1 tlw-text-center md:tlw-text-left tlw-mb-12 sm:tlw-mb-0"
                v-html="$tc('sparkfunnels.campaigns.list.campaigns_count', campaignsCount, { count: campaignsCount })"
              />
            </div>
            <div class="div-buttons">
              <hs-button
                v-if="campaignsCount"
                id="new-campaign-btn"
                class="custom-button tlw-relative"
                variant="outline-primary"
                @click="createCampaign"
                >{{ $t('sparkfunnels.campaigns.list.create_new') }}
                <hs-icon
                  icon="star"
                  size="16px"
                  variant="solid"
                  class="premium-badge tlw-ml-1 tlw-p-1 rounded-circle tlw-absolute"
                />
              </hs-button>
            </div>
          </div>
          <Banner v-if="campaignsCount" />
          <hs-loading v-if="isLoading" />
          <div v-else class="tlw-flex tlw-flex-wrap tlw-w-auto tlw-justify-center sm:tlw-justify-start">
            <Card
              class="tlw-mb-4 tlw-mr-2"
              v-for="campaign in campaigns"
              :key="campaign.id"
              :campaign="campaign"
              @status-toggled="toggleStatus"
              @click="
                selectedCampaign = campaign;
                sidebarOpened = true;
              "
            />
          </div>
        </div>
        <hs-pagination
          v-if="campaignsCount > perPage"
          class="pagination tlw-mt-6 tlw-mb-2 tlw-mx-0"
          v-model="currentPage"
          :total-rows="campaignsCount"
          :per-page="perPage"
          align="center"
        ></hs-pagination>
      </div>
      <hsFunnelsPremiumModal :id="premiumModalId" @click="redirectToSalesPage" />
    </template>
    <template v-slot:sidebar>
      <Details :campaign="selectedCampaign" @hide="sidebarOpened = false" @status-toggled="toggleStatus" />
    </template>
  </hs-sidebar>
</template>

<script>
import Card from './components/Card';
import Details from './components/Details';
import Banner from './components/Banner';
import { hsSidebar, hsLoading } from '@/components';
import hsFunnelsPremiumModal from '@/sparkfunnels/components/FunnelsPremiumModal';
import { campaignsService, funnelApi } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import NoData from '@/components/NoData';
import { mapActions, mapState, mapMutations } from 'vuex';
import { analyticsService } from '@/services';

export default {
  components: {
    Card,
    Details,
    Banner,
    hsSidebar,
    hsLoading,
    NoData,
    hsFunnelsPremiumModal,
  },
  data() {
    return {
      sidebarOpened: false,
      selectedCampaign: {},
      campaignsCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 6,
      campaigns: [],
      isLoading: false,
      hasFunnels: false,
    };
  },
  computed: {
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
    emptyState() {
      return this.hasFunnels
        ? {
            image: require('@/assets/images/campaigns/campaigns-empty.svg'),
            title: this.$t('sparkfunnels.campaigns.list.empty_state.without_campaigns.title'),
            description: this.$t('sparkfunnels.campaigns.list.empty_state.without_campaigns.description'),
            buttonTitle: this.$t('sparkfunnels.campaigns.list.empty_state.without_campaigns.button_title'),
            method: this.createCampaign,
            premium: true,
          }
        : {
            image: require('@/assets/images/campaigns/funnels-empty.svg'),
            title: this.$t('sparkfunnels.campaigns.list.empty_state.without_funnels.title'),
            description: this.$t('sparkfunnels.campaigns.list.empty_state.without_funnels.description'),
            buttonTitle: this.$t('sparkfunnels.campaigns.list.empty_state.without_funnels.button_title'),
            method: this.createFunnel,
            premium: false,
          };
    },
    premiumModalId() {
      return 'premium-modal-id';
    },
  },
  created() {
    this.fetchCampaigns();
    this.fetchFunnels();
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != oldPage && newPage <= this.totalPages) {
        this.fetchCampaigns();
      }
    },
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    ...mapMutations('campaign', ['resetState']),
    fetchCampaigns() {
      this.isLoading = true;
      campaignsService
        .getCampaigns(this.currentPage, this.perPage)
        .then(({ data }) => {
          this.campaigns = data.campaigns.map(campaign => ({ ...campaign, isActive: campaign.status === 'active' }));
          this.totalPages = data.total_pages;
          this.campaignsCount = data.total_count;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.campaigns.list.actions.get_campaigns.error')))
        .finally(() => (this.isLoading = false));
    },
    fetchFunnels() {
      funnelApi
        .getFunnels({ currentPage: 1 })
        .then(({ data }) => (this.hasFunnels = data.total_count > 0))
        .catch(() => (this.hasFunnels = false));
    },
    toggleStatus({ id, newStatus }) {
      campaignsService
        .updateCampaignStatus({ id, action: newStatus ? 'activate' : 'deactivate' })
        .then(() => ToastHelper.successMessage(this.$t('sparkfunnels.campaigns.list.actions.update_campaign.success')))
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.campaigns.list.actions.update_campaign.error')));
    },
    createFunnel() {
      this.$router.push({ name: 'FunnelCreate', query: { location: 'campaigns_list' } });
    },
    createCampaign() {
      if (!this.$FCL.canFunnelsCampaigns()) {
        this.$bvModal.show(this.premiumModalId);
        this.trackAnalytics();
      } else {
        this.resetState();
        this.$router.push({ name: 'CampaignCreate' });
      }
    },
    redirectToSalesPage() {
      this.trackAnalytics();
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide(this.premiumModalId);
    },
    async trackAnalytics() {
      analyticsService.track({
        event: 'Paywall displayed',
        props: {
          email: this.loggedUser.email,
          schoolId: this.selectedSchool.id,
          location: 'Campaigns - Create',
          paywallReason: 'campaigns',
          product: 'Funnels',
        },
      });
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = 'true';
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },
  },
  mounted() {
    if (this.$route.query.pmodal) {
      this.$bvModal.show(this.premiumModalId);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

/deep/.pagination {
  > .col {
    &:first-child,
    &:last-child {
      display: none;
    }
  }
}

.main-content {
  min-height: 550px;
  h3 {
    font-size: inherit;
  }
}

.premium-badge {
  color: $yellow-dark;
  background: $yellow-light-02;
  top: -15px;
  right: 5px;
}
</style>
