<template>
  <div class="container d-flex flex-column align-items-center py-5 px-3">
    <h3 class="font-weight-bold text-center text-dark pb-2 mb-1">
      {{ $t('sparkfunnels.campaigns.create.step_email_configuration.title_first') }}
    </h3>
    <h5 class="text-center pb-5">
      {{ $t('sparkfunnels.campaigns.create.step_email_configuration.title_last') }}
    </h5>
    <template>
      <div class="w-100 d-flex flex-column justify-content-between px-0 mx-0 email-container">
        <h5 class="p-4 font-weight-bold text-dark text-center text-lg-left config-card">
          {{ $t('sparkfunnels.campaigns.create.step_email_configuration.email_config') }}
        </h5>
        <CustomCollapse
          :collapsed="true"
          :title="$t('sparkfunnels.campaigns.create.step_email_configuration.collapse_text.first')"
        >
          <DatetimeSection />
        </CustomCollapse>
        <CustomCollapse :title="$t('sparkfunnels.campaigns.create.step_email_configuration.collapse_text.second')">
          <FillEmailSection />
        </CustomCollapse>
        <CustomCollapse :title="$t('sparkfunnels.campaigns.create.step_email_configuration.collapse_text.third')">
          <EmailTestSection />
        </CustomCollapse>
        <div
          class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-5"
        >
          <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
            <hs-button
              class="mb-2 mb-md-0 custom-button"
              variant="primary"
              @click="$emit('proceed')"
              :disabled="!isFutureDate || !emailTemplate.data.body || !emailTemplate.data.subject"
            >
              {{ $t('sparkfunnels.campaigns.create.step_email_configuration.confirm_email') }}
            </hs-button>
          </span>
          <hs-button class="mr-md-2 custom-button" variant="link" @click="$emit('recede')">
            {{ $t('sparkfunnels.campaigns.create.step_email_configuration.back_step') }}
          </hs-button>
          <b-tooltip
            v-if="!isFutureDate || !emailTemplate.data.body || !emailTemplate.data.subject"
            target="disabled-wrapper"
          >
            <span v-if="!isFutureDate">
              {{ $t('sparkfunnels.campaigns.create.step_email_configuration.disabled_message.future_message') }}
            </span>
            <span v-else-if="!emailTemplate.data.subject">
              {{ $t('sparkfunnels.campaigns.create.step_email_configuration.disabled_message.without_subject') }}
            </span>
            <span v-else-if="!emailTemplate.data.body">
              {{ $t('sparkfunnels.campaigns.create.step_email_configuration.disabled_message.without_body') }}
            </span>
          </b-tooltip>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import CustomCollapse from './CustomCollapse';
import DatetimeSection from './DatetimeSection';
import EmailTestSection from './EmailTestSection';
import FillEmailSection from './FillEmailSection';

export default {
  components: {
    CustomCollapse,
    DatetimeSection,
    EmailTestSection,
    FillEmailSection,
  },
  computed: {
    ...mapState('campaign', ['emailTemplate']),
    isFutureDate() {
      if (this.emailTemplate && this.emailTemplate.datetime.sendAt) {
        return dayjs().isBefore(this.emailTemplate.datetime.sendAt);
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.email-container {
  max-width: 772px;
}

.config-card {
  line-height: 150%;
  background: $pale-cyan;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
</style>
