<template>
  <div class="container d-flex flex-column align-items-center py-5 px-3">
    <h3 class="text-center text-dark font-weight-bold pb-3 mb-3">
      {{ $t('sparkfunnels.campaigns.create.step_funnel_choice.choose_funnel') }}
    </h3>
    <template>
      <hs-loading v-if="isLoading" />
      <div v-else class="w-100 d-flex flex-column justify-content-between px-0 mx-0">
        <div
          class="cards-container overflow-auto d-flex flex-lg-wrap justify-content-start justify-content-lg-center pb-2"
        >
          <card
            v-for="(funnel, index) in funnels"
            :key="index"
            :funnel="funnel"
            :selectedFunnel="selectedFunnel"
            @selected="selectedFunnel = funnel"
          />
        </div>
        <hs-pagination
          v-if="totalRows > perPage"
          class="pagination mx-auto pt-3"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></hs-pagination>
        <div
          class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-5"
        >
          <hs-button
            class="mb-2 mb-md-0 custom-button"
            variant="primary"
            @click="$emit('proceed')"
            :disabled="!selectedFunnel"
          >
            {{ $t('sparkfunnels.campaigns.create.step_funnel_choice.confirm_funnel') }}
          </hs-button>
          <hs-button class="mr-md-2 custom-button" variant="link" :to="{ name: 'Campaigns' }">
            {{ $t('sparkfunnels.campaigns.create.step_funnel_choice.back_to_campaigns') }}
          </hs-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import { funnelApi } from '@/services';
import { hsLoading } from '@/components';
import Card from './Card';
import { mapMutations } from 'vuex';

export default {
  components: { Card, hsLoading },
  computed: {
    selectedFunnel: {
      get() {
        return this.$store.state.campaign.funnel;
      },
      set(funnel) {
        this.setFunnel(funnel);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      totalRows: 0,
      funnels: [],
    };
  },
  watch: {
    currentPage() {
      this.fetchFunnels();
    },
  },
  methods: {
    ...mapMutations('campaign', ['setFunnel']),
    fetchFunnels() {
      this.isLoading = true;
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
      };
      funnelApi
        .getFunnels(params)
        .then(({ data }) => {
          this.funnels = data.funnels;
          this.totalPages = data.total_pages;
          this.perPage = data.per_page;
          this.totalRows = data.total_count;

          if (data.total_count === 0) {
            this.$router.push({ name: 'Funnels' });
          }
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.list.toast.request_error')))
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchFunnels();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.cards-container {
  max-height: calc(50vh);
}

/deep/.pagination > .col {
  &:first-child,
  &:last-child {
    display: none;
  }
}
</style>
