<template>
  <section class="email-trigger-wrapper w-100 d-flex flex-column flex-md-row">
    <div class="d-flex align-items-center mr-0 mr-md-3 mb-0 font-weight-bold flex-1 custom-label">
      {{ $t(`${prefix}.email_tramplate_triger`) }}
    </div>
    <div class="d-flex flex-wrap align-items-center flex-2 pt-2 pt-md-0">
      <hs-date-time v-model="sendAt" :min="new Date()">
        <label slot="date-label">{{ $t(`${prefix}.date`) }}</label>
        <label slot="time-label">{{ $t(`${prefix}.time`) }}</label>
      </hs-date-time>
    </div>
  </section>
</template>

<script>
import { mapMutations } from 'vuex';
import { hsDateTime } from '@/components';
export default {
  components: { hsDateTime },
  computed: {
    prefix: () => 'sparkfunnels.campaigns.create.step_email_configuration.label',
    sendAt: {
      get() {
        return this.$store.state.campaign.emailTemplate.datetime.sendAt;
      },
      set(sendAt) {
        this.setSendAt(sendAt);
      },
    },
  },
  methods: {
    ...mapMutations('campaign', ['setSendAt']),
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 12px;
}

.custom-label {
  font-size: 12px;
}
</style>
