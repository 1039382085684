<template>
  <div class="container overflow-hidden d-flex flex-column align-items-center justify-content-center">
    <b-img-lazy
      :src="require('@/assets/images/campaigns/campaign-finished.svg')"
      alt="Campaign finished image"
      class="image mb-4 mb-md-5"
    />
    <div class="text-container">
      <h3 class="my-4 text-center font-weight-bold">
        {{ $t('sparkfunnels.campaigns.create.step_finished.title') }}
      </h3>
      <p class="description mb-5 text-center">
        {{
          $tc('sparkfunnels.campaigns.create.step_finished.description', leadsCount, {
            count: leadsCount,
          })
        }}
      </p>
      <div
        class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-5"
      >
        <hs-button
          id="ok-btn"
          variant="primary"
          class="d-flex justify-content-center align-items-center mb-2 mb-md-0 custom-button"
          :to="{ name: 'Campaigns' }"
        >
          {{ $t('sparkfunnels.campaigns.create.step_finished.ok_title') }}
        </hs-button>
        <hs-button id="cancel-btn" variant="link" class="mr-md-2 custom-button" @click="$emit('recede')">
          {{ $t('sparkfunnels.campaigns.create.step_finished.cancel_title') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('campaign', ['funnel']),
    leadsCount() {
      return this.funnel.leads_count || 1;
    },
  },
  methods: {
    ...mapMutations('campaign', ['resetState']),
  },
  destroyed() {
    this.resetState();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.image {
  max-width: 150%;
}

.description {
  line-height: 22px;
}

.text-container {
  max-width: 400px;
}

@media only screen and (min-width: $screen-bs-sm) {
  .image {
    max-width: 100%;
  }
}
</style>
