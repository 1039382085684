<template>
  <div
    class="details-wrapper tlw-w-full tlw-absolute tlw-h-full tlw-overflow-auto tlw-shadow tlw-bg-white tlw-flex tlw-items-start tlw-flex-col"
  >
    <div class="details-content tlw-flex tlw-items-start tlw-flex-col tlw-w-full">
      <hs-button variant="link" class="tlw-my-6 tlw-ml-2" @click="$emit('hide')">
        <hs-icon icon="arrow-left" class="text-dark" />
      </hs-button>
      <div class="tlw-px-4 md:tlw-px-4 tlw-flex tlw-items-start tlw-flex-col">
        <p class="campaign-type tlw-mb-2 tlw-uppercase">
          {{ $t('sparkfunnels.campaigns.type.single_mail') }}
        </p>
        <h3 class="tlw-font-bold tlw-mb-6">{{ campaign.name }}</h3>
        <div class="tlw-flex tlw-items-center tlw-tlw-mb-12tlw-">
          <hs-toggle
            :id="campaign.id.toString()"
            @change="newStatus => $emit('status-toggled', { id: campaign.id, newStatus })"
            v-model="campaign.isActive"
          />
          <p class="h6">
            {{ $t(`sparkfunnels.campaigns.list.details.status.${campaign.isActive ? 'active' : 'unactive'}`) }}
          </p>
        </div>
      </div>
      <div class="campaign-metrics tlw-flex tlw-px-2 tlw-self-center tlw-justify-center tlw-flex-col tlw-w-full">
        <div
          v-for="(metric, index) in metrics"
          :key="index"
          class="campaign-metric-card tlw-flex xl:tlw-py-4 tlw-px-4 tlw-items-center tlw-justify-between tlw-mb-1 tlw-ml-0"
        >
          <div class="tlw-flex tlw-flex-1 tlw-items-center">
            <hs-icon :icon="metric.icon" class="mr-3 text-primary" />
            <p class="metric-title tlw-mb-0 tlw-text-center tlw-uppercase">{{ metric.text }}</p>
          </div>
          <h3 class="tlw-font-bold">{{ metric.value }}</h3>
        </div>
      </div>
    </div>
    <div class="tlw-w-full mt-4 pt-5 tlw-flex tlw-flex-col tlw-items-center tlw-px-6">
      <div class="tlw-w-full tlw-flex tlw-items-center tlw-mb-6">
        <hr class="divider-bar tlw-flex-1 tlw-border-0" />
        <hs-icon icon="envelope-open-text" class="divider-icon" />
        <hr class="divider-bar tlw-flex-1 tlw-border-0" />
      </div>
      <hs-loading v-if="isLoading" />
      <template v-else-if="!isLoading && hasEmailTemplates">
        <p class="tlw-font-bold tlw-mb-2 tlw-text-center">"{{ email_templates[0].settings.subject }}"</p>
        <p class="tlw-mb-6 tlw-text-center">
          {{
            this.$t('sparkfunnels.campaigns.list.details.email.date', {
              date: format(email_templates[0].send_at, $t('date.format')),
              time: format(email_templates[0].send_at, $t('hour.format')),
            })
          }}
        </p>
        <p class="email-body tlw-mt-4 tlw-self-start tlw-mb-0">
          {{ $t('sparkfunnels.campaigns.list.details.email.body_text') }}
        </p>
        <p class="tlw-self-start" v-html="email_templates[0].settings.body" />
      </template>
    </div>
  </div>
</template>

<script>
import { hsLoading } from '@/components';
import { campaignsService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { format } from '@/shared/helpers/dates';

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  watch: {
    campaign(newCampaign) {
      this.fetchCampaign(newCampaign.id);
    },
  },
  components: { hsLoading },
  data() {
    return {
      isLoading: false,
      email_templates: null,
    };
  },
  computed: {
    hasEmailTemplates() {
      return this.email_templates ? this.email_templates.length > 0 : false;
    },
    metrics() {
      return [
        {
          icon: 'user-friends',
          text: this.$t('sparkfunnels.campaigns.list.details.metrics.leads_count'),
          value: this.campaign.lead_counter || 0,
        },
        {
          icon: 'envelope-open',
          text: this.$t('sparkfunnels.campaigns.list.details.metrics.opening_rate'),
          value: `${this.fixRate(this.campaign.pct_opening_rate)}%`,
        },
        {
          icon: 'location-arrow',
          text: this.$t('sparkfunnels.campaigns.list.details.metrics.click_through_rate'),
          value: `${this.fixRate(this.campaign.ctr_rate)}%`,
        },
        {
          icon: 'external-link',
          text: this.$t('sparkfunnels.campaigns.list.details.metrics.click_rate'),
          value: `${Number(this.campaign.click_counter) || 0}`,
        },
      ];
    },
  },
  methods: {
    fetchCampaign(id) {
      this.isLoading = true;
      campaignsService
        .getCampaign(id)
        .then(({ data }) => {
          this.email_templates = data.email_templates;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.campaigns.list.actions.get_campaign.error')))
        .finally(() => (this.isLoading = false));
    },
    fixRate(num) {
      if (num) {
        try {
          return Number(num).toFixed(2);
        } catch (error) {
          return 0;
        }
      }
      return 0;
    },
    format,
  },
  mounted() {
    this.fetchCampaign(this.campaign.id);
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  right: 0;
  min-height: 100%;
  top: 0;
  max-width: 330px;

  @media only screen and (min-width: $screen-bs-xl) {
    min-width: 332px;
  }

  @media only screen and (max-width: $screen-bs-sm) {
    max-width: 100%;
  }
}

.details-content {
  background-image: url('~@/assets/images/campaigns/campaign-details.svg');
  background-color: #f1fbfc;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  flex: 0 0 auto;
}

.divider-bar {
  height: 2px;
  background: $grey-10;
}

.divider-icon {
  font-size: 40px;
  color: $grey-17;
}

.email-body {
  line-height: 150%;
  color: $grey-25;
}

.campaign-type {
  font-size: 12px;
  color: $grey-32;
}

.campaign-metrics {
  margin-bottom: -24px;
}

.campaign-metric-card {
  background: $pale-cyan;
}

.metric-title {
  font-size: 12px;
}
</style>
