<template>
  <b-modal :id="modalId" bodyClass="w-100 p-0 d-flex justify-content-center" hide-footer>
    <div v-html="body" />
    <template slot="modal-title">
      <div class="d-flex">
        <h5 class="lh-150 font-weight-bold">{{ $t(`${prefix}.preview`) }}</h5>
        <span class="mx-2 px-1">|</span>
        <router-link class="edit-email" :to="{ name: 'Emails' }">
          <h5 class="lh-150 font-weight-bold">{{ $t(`${prefix}.edit_email`) }}</h5>
        </router-link>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('campaign', {
      body: state => state.emailTemplate.data.body,
    }),
    prefix: () => 'sparkfunnels.campaigns.create.step_email_configuration.preview_modal',
  },
};
</script>

<style lang="scss" scoped>
/deep/.modal {
  padding: 0 !important;
}
/deep/.modal .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
/deep/.modal .modal-content {
  height: 100%;
}
/deep/.modal .modal-body {
  overflow-y: auto;
}
.edit-email {
  color: #00dfed;
  text-decoration: none;
}
</style>
