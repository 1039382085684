<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div class="col-4 px-0">
        <div class="container-body overflow-hidden pb-3 position-relative">
          <div id="email-hover" class="position-absolute w-100 h-100" @click="$bvModal.show(previewModalId)">
            <div class="view-content text-white">
              <hs-icon icon="search-plus" size="29" />
              <h6 class="lh-150 pt-1">{{ $t(`${prefix}.view_email`) }}</h6>
            </div>
          </div>
          <div class="content-body" v-html="body" />
        </div>
      </div>
      <div class="col-8 d-flex align-items-end pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2"><hs-icon icon="lightbulb" size="18" class="text-cherry" /></div>
          <span class="information lh-150" v-html="$t(`${prefix}.information.review`)" />
        </div>
      </div>
    </div>
    <div class="mt-2 pt-1 d-flex justify-content-center col-4">
      <hs-button id="edit-email" variant="primary" :to="{ name: 'Emails' }">{{ $t(`${prefix}.edit_btn`) }}</hs-button>
    </div>
    <PreviewModal :modal-id="previewModalId" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PreviewModal from './PreviewModal';
export default {
  components: { PreviewModal },
  computed: {
    ...mapState('campaign', {
      body: state => state.emailTemplate.data.body,
    }),
    previewModalId: () => 'preview-email-modal',
    prefix: () => 'sparkfunnels.campaigns.create.step_email_configuration',
  },
};
</script>

<style lang="scss" scoped>
.container-body {
  border: 2px solid $grey-20;
  border-radius: 6px;
  > #email-hover {
    cursor: pointer;
    z-index: 1;
    transition: all 0.5s;
    display: grid;
    place-items: center;
    .view-content {
      display: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
      .view-content {
        display: grid;
        place-items: center;
      }
    }
  }
}

.content-body {
  min-height: 307px;
  max-height: 307px;
  transform: scale(0.5);
  transform-origin: 0 0;
  width: 200%;
}

.information {
  font-size: 12px;
  max-width: 360px;
}
</style>
