<template>
  <b-modal :id="id" size="xl" hide-header bodyClass="tlw-p-0 tlw-flex" hide-footer centered>
    <div class="info-wrapper tlw-flex-2 tlw-px-6 tlw-py-12 tlw-flex tlw-flex-col tlw-items-center tlw-justify-center">
      <h3 class="tlw-font-bold tlw-mb-4 tlw-text-center">{{ $t('components.premium-modal.funnels.title') }}</h3>
      <p class="tlw-mb-12 tlw-text-center">
        {{ $t('components.premium-modal.funnels.description') }}
      </p>
      <div class="tlw-flex tlw-flex-wrap tlw-justify-center tlw-mx-6 tlw-mx-6">
        <div class="tlw-flex mr-3 tlw-mb-4" v-for="(feature, index) in features" :key="`feature_${index}`">
          <div class="bg-info-light rounded-circle tlw-py-1 tlw-px-2 tlw-mr-4 tlw-self-start">
            <img v-if="feature === 'herospark'" :src="require(`@/assets/images/commons/${feature}.svg`)" alt="" />
            <hs-icon v-else :icon="feature" class="text-primary"></hs-icon>
          </div>
          <p class="description tlw-font-bold">{{ $t(`components.premium-modal.funnels.features.${index}`) }}</p>
        </div>
      </div>
      <b-button variant="primary" @click="$emit('click')">
        {{ $t('components.premium-modal.funnels.button') }}
      </b-button>
    </div>
    <div
      class="bg-hero tlw-flex-1 tlw-hidden lg:tlw-block bg-info-light"
      :style="{
        backgroundImage: `url('${require('@/assets/images/commons/hero-premium.svg')}')`,
      }"
    />
  </b-modal>
</template>

<script>
export default {
  name: 'hsFunnelsPremiumModal',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    features() {
      return ['envelope', 'cloud-upload', 'filter', 'herospark', 'location-arrow', 'analytics'];
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  font-size: 12px;
  width: 100px;
}

.bg-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.info-wrapper {
  max-width: 700px;
}
</style>
