<template>
  <div>
    <stepper :selectedStage="tabIndex" v-if="!isLastStage" @backToStep="backToStep" />
    <b-tabs v-model="tabIndex" nav-class="d-none" nav-wrapper-class="d-none">
      <b-tab v-for="tab in tabs" :key="tab.id" lazy>
        <component v-bind:is="tab.component" @proceed="proceed" @recede="recede" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Stepper from './components/Stepper';
import CampaignFinished from './components/CampaignFinished';
import EmailConfiguration from './components/EmailConfiguration';
import FillName from './components/FillName';
import FunnelChoice from './components/FunnelChoice';
import Review from './components/Review';
import { v4 } from 'uuid';
import { mapMutations } from 'vuex';

export default {
  components: { Stepper },
  computed: {
    isLastStage() {
      return this.tabIndex === this.tabs.length - 1;
    },
    tabIndex: {
      get() {
        return this.$store.state.campaign.tabIndex;
      },
      set(index) {
        this.setTabIndex(index);
      },
    },
    tabs: () => [
      { component: FunnelChoice, id: v4() },
      { component: FillName, id: v4() },
      { component: EmailConfiguration, id: v4() },
      { component: Review, id: v4() },
      { component: CampaignFinished, id: v4() },
    ],
  },
  methods: {
    ...mapMutations('campaign', ['setTabIndex']),
    backToStep(index) {
      this.tabIndex = index;
    },
    proceed() {
      this.tabIndex++;
    },
    recede() {
      if (this.isLastStage) {
        this.tabIndex = 0;
      } else {
        this.tabIndex--;
      }
    },
  },
};
</script>
