<template>
  <div class="campaign-card tlw-flex tlw-bg-white sm:tlw-shadow tlw-rounded">
    <div class="tlw-flex tlw-flex-col tlw-pt-4 tlw-pl-4 sm:tlw-pl-6 tlw-pr-1 tlw-flex-1">
      <div class="tlw-flex tlw-mb-2 tlw-justify-between tlw-items-center">
        <span class="campaign-template tlw-uppercase">{{ $t('sparkfunnels.campaigns.type.single_mail') }}</span>
        <hs-toggle
          :id="campaign.id.toString()"
          @change="newStatus => $emit('status-toggled', { id: campaign.id, newStatus })"
          v-model="campaign.isActive"
        />
      </div>
      <h5 class="tlw-font-bold tlw-mb-4 lh-150">{{ campaign.name }}</h5>
      <ul class="metric-list list-unstyled tlw-flex tlw-flex-col">
        <li v-for="(metric, index) in metrics" :key="index" class="tlw-flex tlw-items-center tlw-mt-2">
          <hs-icon :icon="metric.icon" class="tlw-mr-2" />
          <p class="metric-text tlw-mb-0 lh-150">{{ metric.text }}</p>
        </li>
      </ul>
    </div>
    <hs-button
      id="btn-details"
      class="tlw-border-0 tlw-shadow h-100 tlw-flex tlw-items-center tlw-justify-center sm:tlw-rounded"
      variant="link"
      @click="$emit('click')"
    >
      <hs-icon icon="chevron-right" />
    </hs-button>
  </div>
</template>

<script>
export default {
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    metrics() {
      return [
        {
          icon: 'user',
          text: this.$tc('sparkfunnels.campaigns.list.card.leads_count', this.campaign.lead_counter || 0, {
            count: this.campaign.lead_counter || 0,
          }),
        },
        {
          icon: 'envelope-open',
          text: this.$t('sparkfunnels.campaigns.list.card.opening_rate', {
            count: this.fixRate(this.campaign.pct_opening_rate),
          }),
        },
        {
          icon: 'location-arrow',
          text: this.$t('sparkfunnels.campaigns.list.card.click_rate', {
            count: this.fixRate(this.campaign.ctr_rate),
          }),
        },
      ];
    },
  },
  methods: {
    fixRate(num) {
      if (num) {
        try {
          return Number(num).toFixed(2);
        } catch (error) {
          return 0;
        }
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
#btn-details {
  max-width: 30px;
}

.campaign-card {
  width: 330px;
}

.campaign-template {
  font-size: 12px;
  color: $grey-25;
}

.metric-list {
  color: $grey-30;
}
</style>
