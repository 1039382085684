<template>
  <section class="email-section" id="email-content">
    <div class="d-flex flex-column mb-3 pb-2">
      <label for="email-subject" class="custom-label font-weight-bold text-truncate mb-0">
        {{ $t(`${prefix}.label.email_subject`) }}
      </label>
      <hs-input id="email-template-subject" :placeholder="$t(`${prefix}.subject_placeholder`)" v-model="subject" />
      <span v-if="!subject" class="missing-subject text-dark">
        {{ $t(`${prefix}.label.missing_subject`) }}
      </span>
    </div>
    <div class="d-flex flex-column mb-3">
      <label for="email-body" class="custom-label font-weight-bold text-truncate mb-0">
        {{ $t(`${prefix}.label.email_body`) }}
      </label>
      <Preview />
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Preview from './Preview';

export default {
  components: { Preview },
  computed: {
    ...mapState('campaign', {
      body: state => state.emailTemplate.data.body,
      mjml: state => state.emailTemplate.data.mjml,
    }),
    ...mapState('email', {
      emailMjml: state => state.mjml,
      emailHtml: state => state.html,
    }),
    prefix: () => 'sparkfunnels.campaigns.create.step_email_configuration',
    subject: {
      get() {
        return this.$store.state.campaign.emailTemplate.data.subject;
      },
      set(subject) {
        this.setSubject(subject);
      },
    },
    variables() {
      return [
        { value: 'lead.name', text: this.$t('sparkfunnels.email_templates.variables.lead_name') },
        { value: 'lead.email', text: this.$t('sparkfunnels.email_templates.variables.lead_email') },
        { value: 'lead.phone', text: this.$t('sparkfunnels.email_templates.variables.lead_phone') },
      ];
    },
  },
  methods: {
    ...mapMutations('campaign', ['setBody', 'setSubject', 'setMjml']),
    ...mapMutations('email', {
      setEmailHtml: 'setHtml',
      setEmailMjml: 'setMjml',
      setEmailVariables: 'setVariables',
    }),
  },
  created() {
    if (this.emailHtml && this.emailMjml) {
      this.setBody(this.emailHtml);
      this.setMjml(this.emailMjml);
    }
    this.setEmailMjml(this.mjml);
    this.setEmailHtml(this.body);
    this.setEmailVariables(this.variables);
  },
};
</script>

<style lang="scss" scoped>
.custom-label,
.missing-subject {
  font-size: 12px;
}
.missing-subject {
  line-height: 150%;
}

/deep/ #email-template-body {
  & .hs-text-editor__content > div {
    height: 100%;
  }
}
</style>
