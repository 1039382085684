<template>
  <div class="container d-flex flex-column align-items-center py-5 px-3">
    <hs-loading v-if="isLoading" />
    <template v-else>
      <h3 class="text-center text-dark pb-5 font-weight-bold custom-title">
        {{ $t(`${prefix}.title`) }}
      </h3>
      <div class="w-100 mt-3 d-flex flex-column bg-white position-relative rounded custom-card">
        <div class="position-absolute top"></div>
        <hs-icon class="position-absolute bg-white icon" variant="light" icon="envelope-open-text" />
        <div class="pt-4 mt-3 pb-5 px-4 d-flex flex-column align-items-center">
          <span class="h5 mb-0">
            {{ $t(`${prefix}.campaign_name`) }}
          </span>
          <span class="h5 mb-1 font-weight-bold">{{ `“${name}”` }}</span>
          <span class="h5 mt-4 mb-2">
            {{ $t(`${prefix}.send_day`) }}
          </span>
          <span
            class="h5 mb-0"
            v-html="
              $t(`${prefix}.datetime_parser`, {
                date: formattedDate,
                hour: formattedTime,
              })
            "
          >
          </span>
        </div>
      </div>
      <div
        class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-5"
      >
        <hs-button class="mb-2 mb-md-0 custom-button" variant="primary" @click="save">
          {{ $t(`${prefix}.confirm_name`) }}
        </hs-button>
        <hs-button class="mr-md-2 custom-button" variant="link" @click="$emit('recede')">{{
          $t(`${prefix}.back_step`)
        }}</hs-button>
      </div>
    </template>
  </div>
</template>

<script>
import { hsLoading } from '@/components';
import { campaignsService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { format } from '@/shared/helpers/dates';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { hsLoading },
  computed: {
    ...mapState('campaign', ['emailTemplate', 'name']),
    ...mapGetters('campaign', ['payloadMounted']),
    formattedDate() {
      return format(this.emailTemplate.datetime.sendAt, this.$t('date.format'));
    },
    formattedTime() {
      return format(this.emailTemplate.datetime.sendAt, this.$t('hour.format'));
    },
    prefix: () => 'sparkfunnels.campaigns.create.step_review',
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    save() {
      this.isLoading = true;
      campaignsService
        .createCampaign(this.payloadMounted)
        .then(() => this.$emit('proceed'))
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.error_message`)))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';
@include div-custom-buttons;

.custom-card {
  max-width: 284px;
  .top {
    border-top: 5px solid $cyan;
    border-radius: 86px;
    width: calc(100% + 4px);
    left: -2px;
  }
}
.icon {
  font-size: 40px;
  left: calc(50% - 20px);
  top: -20px;
  color: $cyan;
  border-radius: 100%;
}

@media only screen and (min-width: $screen-bs-lg) {
  .custom-title {
    max-width: 50vw;
  }
}
</style>
