<template>
  <nav
    class="navigator-wrapper shadow-sm overflow-auto bg-white d-flex justify-content-start justify-content-lg-center p-4"
  >
    <ul class="d-flex flex-row px-0 py-2 py-lg-0 m-0">
      <li
        v-for="stage in stages"
        :key="stage.id"
        class="d-flex align-items-center text-nowrap p-0 pr-4 mr-2"
        :class="{ selected: stage.id === selectedStage, 'done cursor': stage.id < selectedStage }"
        @click="backToStep(stage.id)"
      >
        <div
          class="step-button text-white d-flex justify-content-center align-items-center font-weight-bold rounded-circle"
        >
          <hs-icon v-if="stage.id < selectedStage" variant="light" icon="check" />
          <span v-else-if="stage.id < 4">{{ stage.id + 1 }}</span>
          <hs-icon v-else variant="light" icon="check" />
        </div>
        <div class="ml-2">
          <p class="step-title h6 p-0 ml-1 m-0">
            {{ $t(`sparkfunnels.campaigns.navbar.${stage.text}`) }}
          </p>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    selectedStage: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      stages: [
        { id: 0, text: 'funnel' },
        { id: 1, text: 'name' },
        { id: 2, text: 'email' },
        { id: 3, text: 'review_and_publication' },
        { id: 4, text: 'done' },
      ],
    };
  },
  methods: {
    backToStep(selectedStage) {
      if (selectedStage < this.selectedStage) {
        this.$emit('backToStep', selectedStage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}

.step-button,
.step-title {
  transition: all 0.5s ease;
}

.step-button {
  height: 20px;
  width: 20px;
  background: $grey-25;
}
.step-title {
  color: #bdbdbd;
  line-height: 150%;
}

.done {
  .step-button {
    background: $cyan;
  }
}

.selected {
  .step-button {
    background: #00dfed;
  }

  .step-title {
    color: $grey;
  }
}

@media only screen and (min-width: $screen-bs-lg) {
  .navigator-wrapper {
    background-color: transparent !important;
    border-bottom: 1px solid $grey-17;
  }
}

@media only screen and (max-width: $screen-bs-lg) {
  .navigator-wrapper {
    box-shadow: none !important;
  }
}
</style>
